import React, { Component } from 'react';
import './../assets/css/Home.css'
import {MDBView, MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBAnimation, MDBTooltip} from 'mdbreact';
import ScrollAnimation from 'react-animate-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {isMobile} from 'react-device-detect';

import profil from './../assets/img/public/profil2.png';
import svj from './../assets/img/public/svj.png';
import miamicruise from './../assets/img/public/miamicruise.png';
import moovies from './../assets/img/public/moovies.png';

import Navbar from './../components/Navbar';
import Section from './../components/Section';
import SpeechBubble from './../components/SpeechBubble';
import Area from './../components/Area';
import Timeline from './../components/Timeline';
import Skill from './../components/Skill';
import ContentFilter from './../components/ContentFilter';
import LanguageSkill from './../components/LanguageSkill';
import Hobbie from './../components/Hobbie';
import MailForm from './../components/MailForm';


class Home extends Component {


  getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age.toString();
  }

  getSchoolData() {
    return (
      [
        {
          title: "Baccalauréat Scientifique",
          subtitle: "Avec mention",
          date: "Juin 2017",
          icon: "graduation-cap",
          children: <p>Lycée Condorcet, Saint-Priest</p>
        },
        {
          title: "Ecole informatique",
          subtitle: "42 Lyon",
          date: "Depuis Novembre 2017",
          icon: "school",
          children: <p>Confluences, Lyon</p>
        },
        {
          title: "Stagiaire dans l'entreprise Fatton",
          date: "Juin 2019 - Décembre 2019",
          icon: "briefcase",
          children: <p>Villeurbanne</p>
        },
        {
          title: "Développeur Fullstack",
          date: "Depuis Décembre 2019",
          icon: "briefcase",
          children: <React.Fragment>
            <p>Villeurbanne</p>
            <p>En freelance depuis Janvier 2021</p>
          </React.Fragment>
        }
      ]
    );
  }

  getSkillsData() {
    return (
      [
        {
          categories: ["mobile", "web"],
          element: <Skill id="skill--1" icon="flutter-plain" title="Flutter" level="90" color="flutter" />
        },
        {
          categories: ["web", "program"],
          element: <Skill id="skill-2" icon="csharp-plain" title="C#" level="80" color="csharp" />
        },
        {
          categories: ["web", "program"],
          element: <Skill id="skill--2" icon="dotnetcore-plain" title=".NET Core" level="80" color="dotnetcore" />
        },
        {
          categories: ["web", "program"],
          element: <Skill id="skill-1" icon="go-plain" title="Go" level="80" color="go" />
        },
        {
          categories: ["web", "program"],
          element: <Skill id="skill-19" icon="nodejs-plain" title="Node JS" level="75" color="nodejs" />
        },
        {
          categories: ["program"],
          element: <Skill id="skill-3" icon="python-plain" title="Python" level="70" color="python" />
        },
        {
          categories: ["mobile"],
          element: <Skill id="skill-4" icon="swift-plain" title="Swift" level="60" color="swift" />
        },


        {
          categories: ["web"],
          element: <Skill id="skill-5" icon="html5-plain" title="HTML" level="85" color="html" />
        },
        {
          categories: ["web"],
          element: <Skill id="skill-6" icon="css3-plain" title="CSS" level="80" color="css" />
        },
        {
          categories: ["web", "program"],
          element: <Skill id="skill-7" icon="javascript-plain" title="Javascript" level="75" color="javascript" />
        },
        {
          categories: ["web"],
          element: <Skill id="skill-8" icon="react-plain" title="React JS" level="75" color="react" />
        },
        {
          categories: ["web"],
          element: <Skill id="skill-9" icon="php-plain" title="PHP" level="55" color="php" />
        },


        {
          categories: ["web"],
          element: <Skill id="skill-10" icon="mysql-plain" title="MySQL" level="75" color="mysql" />
        },
        {
          categories: ["web"],
          element: <Skill id="skill-11" icon="postgresql-plain" title="PostgreSQL" level="75" color="postgresql" />
        },
        {
          categories: ["web"],
          element: <Skill id="skill-12" icon="nginx-plain" title="NGINX" level="70" color="nginx" />
        },


        {
          categories: ["program"],
          element: <Skill id="skill-13" icon="c-plain" title="C" level="80" color="c" />
        },
        {
          categories: ["program"],
          element: <Skill id="skill-14" icon="cplusplus-plain" title="C++" level="60" color="cpp" />
        },


        {
          categories: ["tools"],
          element: <Skill id="skill-15" icon="docker-plain" title="Docker" level="75" color="docker" />
        },
        {
          categories: ["tools"],
          element: <Skill id="skill-16" icon="atom-plain" title="Atom" color="atom" />
        },
        {
          categories: ["tools"],
          element: <Skill id="skill-17" icon="visualstudio-plain" title="Visual Studio" color="visualstudio" />
        },
        {
          categories: ["tools"],
          element: <Skill id="skill-18" icon="linux-plain" title="Linux" color="linux" />
        },
      ]
    );
  }

  getProjectsData() {
    return (
      [{
        title: "Gagnant du concours \"Innovez\" de Sciences et vie Junior",
        // subtitle: "Avec mention",
        date: "Avril 2016",
        icon: "trophy",
        tags: ["Python", "Raspberry", "Arduino"],
        children: <p>Réalisation d'une enceinte gestuelle</p>,
        mainImage: {src: svj, alt: "Innovez Sciences et vie junior"},
        btnLink: "https://junior.science-et-vie.com/innovez/alexis-moret-16-ans-invente-la-mubii-3916"
      },
      {
        title: "Programme de cryptage",
        date: "Mai 2016",
        icon: "code",
        tags: ["Python"],
        children: <p>Programme de cryptage/décryptage de données de manière aléatoire</p>
      },
      {
        title: "Application Mobile IOS",
        date: "Avril 2017",
        icon: "mobile",
        tags: ["Swift", "PHP", "Mysql"],
        children: <p>Création d'un mini réseau social</p>
      },
      {
        title: "Projets de l'école 42",
        date: "Depuis Novembre 2017",
        icon: "code",
        tags: ["C"],
        children:
          <React.Fragment>
            <p>Projets ludiques ou reproduction de fontion existantes tel que:</p>
            <div className="timeline-content-enum">
              <p>- Recoder la fontion Printf</p>
              <p>- Recoder une grande partie de la librairie "String"</p>
              <p>- Réaliser un résolveur de Sudoku</p>
            </div>
          </React.Fragment>
      },
      {
        title: "Réalisation d'un jeu vidéo",
        date: "Décembre 2018",
        icon: "gamepad",
        tags: ["Unity", "csharp"],
        children: <p>Réalisation d'un jeu d'arcade sur Windows et MacOS disponible sur la plateforme Steam</p>,
        mainImage: {src: miamicruise, alt: "Miami Cruise"},
        btnLink: "https://store.steampowered.com/app/862260/Miami_Cruise/"
      },
      {
        title: "Backend en Go",
        date: "Mai 2019",
        icon: "code",
        tags: ["Go", "Docker", "Nginx", "Mysql", "Linux"],
        children: <p>Réalisation d'un backend en langage Go sous la forme d'une API REST</p>
      },
      {
        title: "Site web de tracking",
        date: "Juin 2019 - Décembre 2019",
        icon: "code",
        tags: ["csharp", "React", "SQLServer"],
        children: <p>Site de tracking de marchandise de la société Fatton créé en ASP, React avec du Server Side Rendering</p>
      },
      {
        title: "Création de l'application Moovies",
        date: "2021",
        icon: "mobile",
        tags: ["Flutter"],
        children: <p>Réalisation d'une application de recommendations de films en Flutter, disponible sur IOS et Android</p>,
        mainImage: {src: moovies, alt: "Moovies"},
        btnLink: "https://moovies.io/getapp"
      },]
    );
  }




  tableLine = (props) => {
    return (
      <MDBRow>
        <MDBCol md="4" sm="12">
          <strong className="title">{props.title}</strong>
        </MDBCol>
        <MDBCol md="8" sm="12">
          <span className="value">{props.value}</span>
        </MDBCol>
      </MDBRow>
    );
  }

  render() {
    return (
      <React.Fragment>

      <Navbar/>

      <MDBView>
        <MDBContainer>

          {/* SECTION INTRO */}
          <div className="section section-intro">
            <MDBRow className="align-items-center">
              <MDBCol md="6" sm="12">
                <div className="section-intro-center">
                  <MDBAnimation type="bounceIn" duration="700ms" delay="200ms">
                    <SpeechBubble center>HELLO WORLD !</SpeechBubble>
                  </MDBAnimation>
                  <div className="section-intro-titles">
                    <h1 className="title">Je suis Alexis Moret</h1>
                    <h2 className="subtitle">Et je suis développeur informatique.</h2>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md="6" sm="12" className="text-center">
                <MDBAnimation type="fadeIn" delay="300ms">
                  <LazyLoadImage
                  className="section-intro-img"
                  alt="Profil"
                  effect="blur"
                  src={profil} />
                  {/* <img src={profil} alt="Profil" className="section-intro-img" /> */}
                </MDBAnimation>
              </MDBCol>
            </MDBRow>
          </div>
          {/* ----------- */}


          {/* SECTION ABOUT ME */}
          <Section name="section-aboutme" icon="user" title="A propos de moi">
            <MDBRow>
              <MDBCol md="7" sm="12">
                <p>
                Passionné d’informatique depuis {this.getAge("01/12/2013")} ans, la programmation est pour moi une réelle vocation.
                Pendant toutes ces années j’ai réalisé de nombreux projets personnels dans divers domaines tel que les sites web,
                les applications mobiles, les objets connectés ou encore les jeux vidéos.
                Travaillant actuellement en tant que développeur freelance, je suis ouvert à tout type de projets, missions ou discussions.
                </p>
              </MDBCol>
              <MDBCol md="5" sm="12" className="text-center">
                <ScrollAnimation offset={100} animateIn="fadeInDown">
                  <Area>
                    <div className="infos-table">
                      <this.tableLine title="age" value={this.getAge("08/01/1999")} />
                      {/* <this.tableLine title="addresse" value="7 Rue Fabian Martin Mions France" /> */}
                      <this.tableLine title="email" value="moret.alexis99@gmail.com" />
                      {/* <this.tableLine title="numero" value="06 95 87 43 81" /> */}
                    </div>
                  </Area>
                </ScrollAnimation>
              </MDBCol>
            </MDBRow>
            <div className="section-aboutme-contact-btns text-center">
              <ScrollAnimation offset={100} animateIn="bounceIn">
                <MDBBtn href="mailto:moret.alexis99@gmail.com" tag="a">
                  <MDBIcon color="white" icon="envelope" />
                </MDBBtn>
              </ScrollAnimation>
                {/* {isMobile ?
                (
                  <ScrollAnimation offset={100} delay={150} animateIn="bounceIn">
                    <MDBBtn href="tel:+33695874381" tag="a">
                      <MDBIcon color="white" icon="phone" />
                    </MDBBtn>
                  </ScrollAnimation>
                ) : (
                  <MDBTooltip clickable placement="bottom">
                    <MDBBtn>
                      <MDBIcon color="white" icon="phone" />
                    </MDBBtn>
                    <div>06 95 87 43 81</div>
                  </MDBTooltip>
                )
                } */}
              <ScrollAnimation offset={100} delay={300} animateIn="bounceIn">
                <MDBBtn href="https://www.linkedin.com/in/alexis-moret/" style={{width:"58px"}} tag="a">
                  <MDBIcon color="white" fab icon="linkedin" />
                </MDBBtn>
              </ScrollAnimation>
            </div>
          </Section>
          {/* ----------- */}


          {/* SECTION ABOUT ME */}
          <Section timeline name="section-school" icon="school" title="Formations et Experiences">
            <Timeline data={this.getSchoolData()} id="school" />
          </Section>
          {/* ----------- */}


          {/* SECTION SKILLS */}
          <Section name="section-skills" icon="star" title="Mes compétences">
            <ContentFilter id={1}
            filters={[{"field": "all", "title": "Tout"}, {"field": "web", "title": "Web"}, {"field": "program", "title": "Programmes"}
            , {"field": "mobile", "title": "Mobile"}, {"field": "tools", "title": "Outils / Plateformes"}]}
            barColor="#46e29a"
            content={this.getSkillsData()}
            />
          </Section>
          {/* ----------- */}


          {/* SECTION PROJECTS */}
          <Section timeline name="section-projects" icon="desktop" title="Projets et développement">
            <Timeline data={this.getProjectsData()} id="school-2" />
            <ScrollAnimation offset={100} animateIn="fadeInUp">
              <div className="cv-project text-center">
                <h2>Ce Site Web a été codé en React et en GO,</h2>
                <h2>le tout géré par AWS.</h2>
                <div className="cv-lang-icons">
                  <MDBAnimation type="bounce" infinite duration="2s">
                    <i className="devicon-react-plain" />
                  </MDBAnimation>
                  <MDBAnimation type="bounce" infinite duration="2s" delay="0.5s">
                    <i className="devicon-go-plain" />
                  </MDBAnimation>
                  <MDBAnimation type="bounce" infinite duration="2s" delay="1s">
                    <i className="devicon-amazonwebservices-plain" />
                  </MDBAnimation>
                </div>
              </div>
            </ScrollAnimation>
          </Section>
          {/* ----------- */}



          {/* SECTION LANGUAGES */}
          <Section name="section-languages" icon="language" title="Langues">
            <div className="languages text-center">
              <LanguageSkill lang="Français" level="10" />
              <LanguageSkill lang="Anglais" level="5" />
              <LanguageSkill lang="Allemand" level="2" />
            </div>
          </Section>
          {/* ----------- */}


          {/* SECTION HOBBIES */}
          <Section name="section-hobbies" icon="futbol" title="Centres d'intérêts">
            <MDBRow>
              <MDBCol lg="3" md="6" sm="6">
                <ScrollAnimation offset={100} delay={0} animateIn="fadeInDown">
                  <Hobbie icon="laptop-code" title="Coder des projets personnels" />
                </ScrollAnimation>
              </MDBCol>
              <MDBCol lg="3" md="6" sm="6">
                <ScrollAnimation offset={100} delay={100} animateIn="fadeInDown">
                  <Hobbie icon="dumbbell" title="Sport" />
                </ScrollAnimation>
              </MDBCol>
              <MDBCol lg="3" md="6" sm="6">
                <ScrollAnimation offset={100} delay={200} animateIn="fadeInDown">
                  <Hobbie icon="gamepad" title="Jeux vidéos" />
                </ScrollAnimation>
              </MDBCol>
              <MDBCol lg="3" md="6" sm="6">
                <ScrollAnimation offset={100} delay={300} animateIn="fadeInDown">
                  <Hobbie icon="film" title="Film /Série / Mangas" />
                </ScrollAnimation>
              </MDBCol>
            </MDBRow>
          </Section>
          {/* ----------- */}



          {/* SECTION CONTACT */}
          <Section timeline name="section-contact" icon="envelope" title="Contactez moi !">
            <ScrollAnimation offset={100} animateIn="fadeInUp">
              <MailForm/>
            </ScrollAnimation>
          </Section>
          {/* ----------- */}

        </MDBContainer>
      </MDBView>

      </React.Fragment>
    );
  }

}

export default Home;
