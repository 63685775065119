import React, { Component } from 'react';
import {MDBBadge, MDBBtn, MDBMask, MDBView} from 'mdbreact';
import './../assets/css/Timeline.css'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

class Timeline extends Component {

    constructor(props) {
        super(props);

        this.scrollLineScreenStart = window.outerHeight / 4;

        this.styleLine = document.head.appendChild(document.createElement("style"));

        this.indexActiveIcon = 0;

        this.timelineFinish = false;

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        const timelineParent = document.getElementById(this.props.id);

        //this.lineOffsetTop = timelineParent.getElementsByClassName("vertical-timeline")[0].offsetTop;

        this.scrollLineTimelineStart = timelineParent.offsetTop;
        this.scrollLineTimelineStop = timelineParent.offsetTop + timelineParent.offsetHeight;

        this.icons = timelineParent.getElementsByClassName("vertical-timeline-element-icon");
        this.elements = timelineParent.getElementsByClassName("vertical-timeline-element-content");

        this.elementsT = timelineParent.getElementsByClassName("vertical-timeline-element");

        // console.log("ScreenStart: "+this.scrollLineScreenStart);
        // console.log("TimelineStart: "+this.scrollLineTimelineStart);
        // console.log("TimelineStop: "+this.scrollLineTimelineStop);

        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        if (!this.timelineFinish) {
            window.removeEventListener('scroll', this.onScroll);
        }
    }

    onScroll() {
        var offset = this.scrollLineScreenStart + window.scrollY;
        if (offset >= this.scrollLineTimelineStart && offset <= this.scrollLineTimelineStop) {
            var percent = this.invlerp(this.scrollLineTimelineStart, this.scrollLineTimelineStop, offset) * 100;

            if (percent <= 1) {percent = 0;}
            else if (percent >= 99) {percent = 100;}

            if ((this.indexActiveIcon === 0 && percent >= 4) || (percent >= (this.indexActiveIcon * (100 / this.icons.length)) + 4)) {
                this.icons[this.indexActiveIcon].classList.add("active-icon");
                this.elements[this.indexActiveIcon].classList.add("vertical-timeline-element-content-active");
                this.indexActiveIcon++;
            }

            this.styleLine.innerHTML = "#"+this.props.id+" .vertical-timeline::after {height: "+percent+"%;}";

            if (this.indexActiveIcon === this.icons.length && percent === 100) {
                this.timelineFinish = true;
                window.removeEventListener('scroll', this.onScroll);
            }
        }
    }

    invlerp(a, b, v) {
        return ( v - a ) / ( b - a )
    }






    Elements = () => {
        const data = this.props.data;

        let elements = [];
        for (var i = 0; i < data.length; i++) {
            var element = data[i];

            elements.push(
                <VerticalTimelineElement
                date={element.date}
                iconStyle={{ background: element.color ? element.color : "white", color: 'primary' }}
                icon={<i className={(this.props.iconstyle ? this.props.iconstyle : "fas") + " fa-"+element.icon + " icon"}/>}
                key={i}
                className={element.tags ? "timeline-element-tags" : ""}
                >
                    {element.tags &&
                        <div className="tags">
                            {
                                element.tags.map(function(tag, key) {
                                    var tagText = tag === "csharp" ? "C#" : tag;
                                    return (
                                        <MDBBadge key={key} pill color={null} className={tag.toLowerCase()+"-color"}>{tagText}</MDBBadge>
                                    );
                                })
                            }
                        </div>
                    }
                    {element.title &&
                        <h3 className="vertical-timeline-element-title">{element.title}</h3>
                    }
                    {element.subtitle &&
                        <h4 className="vertical-timeline-element-subtitle">{element.subtitle}</h4>
                    }
                    {element.children}
                    {element.mainImage &&
                        <MDBView hover zoom>
                            <img className="rounded main-image" alt={element.mainImage.alt} src={element.mainImage.src} />
                            <MDBMask className="flex-center"/>
                        </MDBView>
                    }
                    {element.btnLink &&
                        <MDBBtn color="primary" className="main-btn-link" href={element.btnLink}>Voir</MDBBtn>
                    }
                </VerticalTimelineElement>
            );
        }
        return elements;
    }

    render() {
        return (
            <div id={this.props.id} className={(this.props.className ? this.props.className : "" )+ " timeline"}>
                <VerticalTimeline layout={this.props.oneColumn ? "1-column" : "2-columns"}>
                    <this.Elements />
                </VerticalTimeline>
            </div>
        );
    }
  
  }
  
  export default Timeline;