import React, { Component } from 'react';
import { MDBIcon } from "mdbreact";
import './../assets/css/Hobbie.css'

class Hobbie extends Component {

  render() {
    return (
        <div className="hobbie text-center">
            <div className="hobbie-container">
                <MDBIcon className="hobbie-icon" icon={this.props.icon} />
                <span className="hobbie-title">{this.props.title}</span>
            </div>
        </div>
    );
  }

}

export default Hobbie;
