import React, { Component } from 'react';
import './../assets/css/Section.css'

class Section extends Component {

  render() {
    return (
      <div id={this.props.name} className={"section " + (this.props.timeline ? "section-timeline " : "") + (this.props.name ? this.props.name : "")}>
          <div className="section-title">
            <h2>
              { this.props.icon &&
                <i className={(this.props.iconstyle ? this.props.iconstyle : "fas") + " fa-"+this.props.icon + " text-primary "}/>
              }
              {this.props.title}
            </h2>
          </div>
          <div className="section-content">
            {this.props.children}
          </div>
      </div>
    );
  }

}

export default Section;
