import React, { Component } from 'react';
import './../assets/css/SpeechBubble.css'
// import {MDBView, MDBContainer} from 'mdbreact';

class SpeechBubble extends Component {

  render() {

    var addClasses = " ";
    addClasses += this.props.center ? "text-center " : "";
    addClasses += this.props.className ? this.props.className : "";

    return (
      <div className={"speech-bubble" + addClasses}>
          {this.props.children}
      </div>
    );
  }

}

export default SpeechBubble;
