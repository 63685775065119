import React, { Component } from 'react';
import './../assets/css/Skill.css'
import './../assets/css/SkillColor.css'

class Skill extends Component {

  componentDidMount() {
    this.skill = document.getElementById(this.props.id);
    if (!this.props.level) return ;
    this.skillBar = this.skill.getElementsByClassName("skill-bar")[0];
    this.skillText = this.skill.getElementsByClassName("skill-bar-text")[0];
  }

  handleMouseEnter = () => {
    if (!this.props.level) return ;
    this.skillBar.style.width = this.props.level + "%";
    this.skillText.style.left = "calc(" + (parseInt(this.props.level, 10) - 50).toString() + "% + 5px)";
  }

  handleMouseLeave = () => {
    if (!this.props.level) return ;
    this.skillBar.style.width = "0%";
    this.skillText.style.left = "calc(-50% + 5px)";
  }

  render() {
    return (
      <div id={this.props.id ? this.props.id : null}
           className={"skill text-center " + this.props.color+"-color " + (this.props.className ? this.props.className : "")}>
          <div className="skill-head">
            <i className={"devicon-"+this.props.icon}/>
            <h4>{this.props.title}</h4>
          </div>

          { this.props.level &&
            <div className="skill-footer">
                <div className="skill-outbar">
                  <div className={"skill-bar " + this.props.color+"-color "} style={{width: this.props.level+"%"}} />
                </div>
                <span className={"skill-bar-text " + this.props.color+"-color "} style={{left: "calc("+(parseInt(this.props.level, 10) - 50).toString()+"% + 5px)"}}>{this.props.level + "%"}</span>
            </div>
          }
      </div>
    );
  }

}

export default Skill;
