import React, { Component } from 'react';
import {MDBRow, MDBCol} from 'mdbreact';
import ScrollAnimation from 'react-animate-on-scroll';
import './../assets/css/ContentFilter.css'

class ContentFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: "all"
        };
    }

    componentDidMount() {
        const contentFilter = document.getElementById(this.props.id.toString());

        this.btns = contentFilter.getElementsByClassName("filter-btn");
        this.line = contentFilter.getElementsByClassName("filter-line")[0];

        this.grid = contentFilter.getElementsByClassName("content-grid")[0];


        this.line.style.width = this.btns[0].clientWidth+"px";
        this.line.style.left = this.btns[0].offsetLeft+"px";
    }

    
    changeFilter = (e) => {
        const btn = e.target;
        const field = btn.id;
        
        this.line.style.width = btn.clientWidth+"px";
        this.line.style.left = btn.offsetLeft+"px";

        this.setState({filter: field});

    }

    displayContent = (props) => {
        const filter = props.filter;
        const content = this.props.content;

        let objs = [];
        for (var i = 0; i < content.length; i++) {
            const elem = content[i];
            
            if (filter === "all" || elem.categories.includes(filter)) {
                objs.push(elem.element);
            }
        }

        const nbRow = Math.floor(objs.length % 4 === 0 ? objs.length / 4 : (objs.length < 4 ? 1 : (objs.length / 4) + 1));
        const lastRowNbCol = objs.length % 4 !== 0 ? objs.length % 4 : 4;

        let rows = [];
        var skillIndex = 0;

        for (i = 0; i < nbRow; i++) {
            let cols = [];
            const nbCol = i === nbRow - 1 ? lastRowNbCol : 4;

            for (var j = 0; j < nbCol; j++) {
                cols.push(
                    <MDBCol key={skillIndex} lg="3" md="6" sm="6" className="content-col">
                        <ScrollAnimation offset={100} duration={0.6} animateIn="fadeInDown">
                            {objs[skillIndex++]}
                        </ScrollAnimation>
                    </MDBCol>
                );
            }

            rows.push(
                <MDBRow key={"row-"+i.toString()} center middle>
                    {cols}
                </MDBRow>
            );
        }

        return rows;
    }


    render() {

        let btns = [];
        for (var i = 0; i < this.props.filters.length; i++) {
            var filter = this.props.filters[i];
            btns.push(
                <button onClick={this.changeFilter} key={i} id={filter.field} className="filter-btn">{filter.title}</button>
            );
        }

        return (
            <div id={this.props.id.toString()} className="content-filter text-center">
                <div className="filter-group">
                    <div className="filter-btns">
                        {btns}
                    </div>
                    <div className="filter-bar">
                        <span style={{backgroundColor: this.props.barColor ? this.props.barColor : "green"}} className="filter-line" />
                    </div>
                </div>
                <div className="content-grid">
                    <this.displayContent filter={this.state.filter} />
                </div>
            </div>
        );
    }

}

export default ContentFilter;
