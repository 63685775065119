import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import "./assets/scss/mdb.scss"

import "animate.css/animate.min.css";
import "devicon/devicon.min.css";

import Home from './pages/Home'
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route component={Home} />
        </div>
      </Router>
    );
  }
}

export default App;
