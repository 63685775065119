import React, { Component } from 'react';
import {MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon, MDBAnimation,
MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter} from 'mdbreact';
import axios from 'axios';
import './../assets/css/MailForm.css'

class MailForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: {
                value: "",
                valid: true
            },
            email: {
                value: "",
                valid: true
            },
            message: {
                value: "",
                valid: true
            },
            wasInvalid: false,
            loading: false,
            modal: {
                isOpen: false,
                title: "",
                text: ""
            }
        };
    }

    checkTextInput(value) {
        return value.length > 1;
    }
    checkEmailInput(value) {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    }
    checkInput(name, value) {
        if (name === "email")
            return this.checkEmailInput(value);
        return this.checkTextInput(value);
    }


    changeHandler = event => {

        var valid = true;
        if (this.state.wasInvalid)
            valid = this.checkInput(event.target.name, event.target.value);

        this.setState({ [event.target.name]: {value: event.target.value, valid: valid} });
    };



    input = (props) => {
        return (
            <div className="input-container">
                <MDBInput type={props.area ? "textarea" : "text"} rows={props.area ? 5 : null} name={props.name} label={props.label}
                          icon={props.icon} value={props.value} onChange={this.changeHandler} size="lg" />
                { !props.valid &&
                    <MDBAnimation type="fadeInRight" duration="500ms">
                        <div className="invalid-input-text">
                            {props.invalidText}
                        </div>
                    </MDBAnimation>
                }
            </div>
        );
    }

    sendBtn = (props) => {
        return (
            <div id={props.id} className="mail-form-btn-view">
                <MDBBtn onClick={this.submit} color="primary">
                    { !this.state.loading ? (
                        <div><MDBIcon icon="paper-plane" /> Envoyer</div>
                    ) : (
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    )
                    }
                </MDBBtn>
                {/* <div>
                    <MDBBadge color="info">Je répond en général en moins de 48h</MDBBadge>
                </div> */}
            </div>
        );
    }


    toggle = (toOpen = true, title = null, text = null) => {
        this.setState({
          modal: {
              isOpen: toOpen ? !this.state.modal.isOpen : this.state.modal.isOpen,
              title: title ? title : this.state.title,
              text: text ? text : this.state.text
            }
        });
    }
    modal = () => {
        return (
            <MDBModal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                <MDBModalHeader toggle={this.toggle}>{this.state.modal.title}</MDBModalHeader>
                <MDBModalBody>
                    {this.state.modal.text}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="primary" onClick={this.toggle}>Fermer</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        );
    }

    submit = () => {

        let inputNames = ["name", "email", "message"];
        var valid = true;

        for (let name of inputNames) {
            if (!this.checkInput(name, this.state[name].value)) {
                this.setState({[name]: {value: this.state[name].value, valid: false}, wasInvalid: true});
                valid = false;
            }
        }
        if (!valid)
            return ;
        
        this.setState({loading: true});

        axios.post(`https://cnl0sy8wh3.execute-api.eu-west-3.amazonaws.com/dev/sendmail`, { 
            name: this.state.name.value,
            email: this.state.email.value,
            message: this.state.message.value
         })
        .then(res => {
            this.toggle(true, "Mail envoyé !", "Merci de m'avoir contacté ! J'essaierai de vous répondre au plus vite !")
            this.setState({loading: false});
        })
        .catch(error => {

            let statusCode = error.response.status;

            if (statusCode === 400) {
                this.toggle(true, "Erreur", "Vous n'avez pas correctement rempli le formulaire")
            } else {
                this.toggle(true, "Erreur", "Erreur inattendue...")
            }
            this.setState({loading: false});
        })

    }


    render() {
        return (
            <React.Fragment>
                <this.modal />
                <div className="mail-form">

                    <MDBRow middle center>
                        <MDBCol md="7" sm="12">
                            <this.input name="name" label="Nom" icon="user" 
                            value={this.state.name.value} valid={this.state.name.valid} invalidText="Nom invalide" />
                            <this.input name="email" label="Adresse mail" icon="envelope" 
                            value={this.state.email.value} valid={this.state.email.valid} invalidText="Adresse mail invalide" />
                        </MDBCol>
                        <MDBCol md="5" middle className="text-center">
                            <this.sendBtn id="mail-form-btnview-lg" />
                        </MDBCol>
                    </MDBRow>

                    <this.input area name="message" label="Message" icon="comment-alt" 
                    value={this.state.message.value} valid={this.state.message.valid} invalidText="Message invalide" />

                    <this.sendBtn id="mail-form-btnview-sm" />
                </div>
            </React.Fragment>
        );
    }

}

export default MailForm;
