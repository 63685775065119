import React, { Component } from 'react';
import './../assets/css/Area.css'

class Area extends Component {

  render() {
    return (
      <div className="area">
          {this.props.children}
      </div>
    );
  }

}

export default Area;
