import React, { Component } from 'react';
import {MDBRow, MDBCol} from 'mdbreact';
import ScrollAnimation from 'react-animate-on-scroll';
import './../assets/css/LanguageSkill.css'

class LanguageSkill extends Component {

    setRounds = () => {
        let rounds = [];
        const level = parseInt(this.props.level, 10);

        for (var i = 0; i < 10; i++) {
            rounds.push(
                <ScrollAnimation key={i} offset={50} animateIn="fadeInLeft" duration={0.2} delay={(100 + (i * 120))}>
                    <div className={"round " + (i <= level - 1 ? "full" : "")}></div>
                </ScrollAnimation>
            );
        }
        return rounds;
    }

    render() {
        return (
            <div className="language-skill">
                <MDBRow center middle>
                    <MDBCol md="5" sm="12" className="column title">
                        <h4>{this.props.lang}</h4>
                    </MDBCol>
                    <MDBCol md="7" sm="12" className="column rounds">
                        <this.setRounds/>
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

}

export default LanguageSkill;
