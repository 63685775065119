import React, { Component } from 'react';
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBIcon } from "mdbreact";
import { Link } from "react-scroll";
import './../assets/css/Navbar.css'

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            notOnTop: false,
            sectionsActive: {}
        }

        this.sections = ["section-aboutme", "section-school", "section-skills", "section-projects", "section-languages", "section-hobbies"];

        for (let section of this.sections) {
            this.state.sectionsActive[section] = (section === "section-aboutme");
        }

        this.isOnTop = true;
        this.maxScrollOnTop = 30;
        this.firstScroll = true;

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        this.navbarContainer = document.getElementById("navbar-container");
        this.navBar = this.navbarContainer.getElementsByClassName("navbar")[0];

        this.scrollOffset = window.outerHeight / 3;

        window.addEventListener('scroll', this.onScroll);
    }


    onScroll() {
        if (this.firstScroll) {
            this.isOnTop = !(window.scrollY < this.maxScrollOnTop);
            this.sections = document.getElementsByClassName("section");

            this.firstScroll = false;
        }

        if (window.scrollY >= this.maxScrollOnTop && this.isOnTop)
            this.animNotOnTop();
        else if (window.scrollY < this.maxScrollOnTop && ! this.isOnTop)
            this.animOnTop();
        
        for (var i = 0; i < this.sections.length; i++) {
            if (window.scrollY + this.scrollOffset < this.sections[i].offsetTop) {
                if (i > 0)
                    i--;
                break ;
            }
        }

        let newState = {};
        for (var j = 0; j < this.sections.length; j++) {
            newState[this.sections[j].id] = j === i;
        }
        this.setState({sectionsActive : newState});
        
    }

    animNotOnTop() {
        this.setState({ notOnTop: true });
        this.navbarContainer.classList.add("navbar-container-on-scroll");
        this.navBar.style.borderBottomColor = "transparent";
        this.isOnTop = false;
    }

    animOnTop() {
        this.setState({ notOnTop: false });
        this.navbarContainer.classList.remove("navbar-container-on-scroll");
        this.navBar.style.borderBottomColor = "white";
        this.isOnTop = true;
    }

    scrollLink = (props) => {
        return (
            <Link onClick={this.handleLinkClick} className="nav-link Ripple-parent" to={props.to} smooth={true}>
                {props.children}
            </Link>
        );
    }




    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleLinkClick = () => {
        if (this.state.isOpen)
            this.toggleCollapse();
    }

    render() {
        return (
            <React.Fragment>
                {this.state.notOnTop && <div className="empty-nav" />}
                <MDBContainer id="navbar-container" className="navbar-container">
                    <MDBNavbar scrolling dark expand="md">
                        <MDBNavbarBrand>
                            <strong className="white-text">Alexis Moret</strong>
                        </MDBNavbarBrand>
                        <MDBNavbarToggler onClick={this.toggleCollapse} />
                        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                        <MDBNavbarNav left>
                            <MDBNavItem active={this.state.sectionsActive["section-aboutme"]}>
                                <this.scrollLink to="section-aboutme">A propos de moi</this.scrollLink>
                            </MDBNavItem>
                            <MDBNavItem active={this.state.sectionsActive["section-school"]}>
                                <this.scrollLink to="section-school">Formations</this.scrollLink>
                            </MDBNavItem>
                            <MDBNavItem active={this.state.sectionsActive["section-skills"]}>
                                <this.scrollLink to="section-skills">Compétences</this.scrollLink>
                            </MDBNavItem>
                            <MDBNavItem active={this.state.sectionsActive["section-projects"]}>
                                <this.scrollLink to="section-projects">Projets</this.scrollLink>
                            </MDBNavItem>
                            <MDBNavItem active={this.state.sectionsActive["section-languages"]}>
                                <this.scrollLink to="section-languages">Langues</this.scrollLink>
                            </MDBNavItem>
                            <MDBNavItem active={this.state.sectionsActive["section-hobbies"]}>
                                <this.scrollLink to="section-hobbies">Centres d'intérêts</this.scrollLink>
                            </MDBNavItem>
                        </MDBNavbarNav>
                        <MDBNavbarNav right>
                            <MDBNavItem>
                                <a className="nav-link Ripple-parent waves-effect waves-light" href="https://www.linkedin.com/in/alexis-moret/">
                                    <MDBIcon fab icon="linkedin" />
                                </a>
                            </MDBNavItem>
                        </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBNavbar>
                </MDBContainer>
            </React.Fragment>
        );
    }

}

export default Navbar;
